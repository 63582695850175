import * as React from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, ActionButton, Checkbox } from 'office-ui-fabric-react';

import { logPageAction } from './../../../lib/ApplicationInsights';

import { isBillingAddressRequired } from './../../../helpers/billingCheck';
import { isWalkInCenterSelectedForMoreOffers } from './../../../helpers/shippingChecks';

import {
    allSelectedOfferSelector,
    billingAddressSelector,
    Progress,
    progressSelector,
    saveBillingAddress,
    selectedShippingOfferSelector,
    shippingAddressSelector,
    updateCreateOrderProgress,
} from './../../../store/orderCreation';

import AddressManager from './../../../components/Address/AddressManager';

import Styles from './styles.module.scss';
import { userAgentTierSelector } from './../../../store/user';
import { checkIsUserEnterpriseCategoryFromTier } from './../../../lib/userTierConfig';

export default function BillingAddress(props: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = React.useState(false);
    const [useShippingData, setUseShippingData] = React.useState<boolean>(false);
    const selectedShippingOffers = useSelector(state => selectedShippingOfferSelector(state));
    const selectedOffer = useSelector(state => allSelectedOfferSelector(state));
    const shippingAddress = useSelector(state => shippingAddressSelector(state));
    const userAgentTier = useSelector(state => userAgentTierSelector(state));
    const walkInCenterOfferSelected = isWalkInCenterSelectedForMoreOffers(selectedShippingOffers);
    const currentStage: Progress = useSelector(state => progressSelector(state));
    //to get the current tier information and check whether Enterprise category or not
    const isEnterpriseCustomer = checkIsUserEnterpriseCategoryFromTier(userAgentTier);

    if (!isBillingAddressRequired(selectedShippingOffers, selectedOffer, userAgentTier)) {
        return null;
    }

    React.useEffect(() => {
        if (walkInCenterOfferSelected && !isEditing) {
            setUseShippingData(true);
            if (currentStage !== Progress.Registration) {
                setIsEditing(true);
            }
        }
    }, [currentStage]);


    const checkboxStyles = () => {
        return {
            root: {
                marginTop: '30px',
            },
        };
    };

    const EditButton = () => (
        <span className={Styles.editContainer}>
            <Icon
                className={clsx('ms-Icon', Styles.editIcon)}
                iconName="edit"
                ariaLabel={t('edit')}
            />
            <ActionButton aria-label={t('edit_billing')} onClick={handleEdit}>
                {t('edit')}
            </ActionButton>
        </span>
    );

    const billingAddress = useSelector(state =>
        billingAddressSelector(state)
    );

    function handleEdit() {
        logPageAction('Billing method edit button clicked');
        setIsEditing(true);
    }

    function handleUseShippingData() {
        setUseShippingData(!useShippingData);
        setIsEditing(true);
    }

    function onContinue(addressInputFromFields: any) {
        setIsEditing(false);
        dispatch(saveBillingAddress(addressInputFromFields));
        dispatch(updateCreateOrderProgress(Progress.Registration));
    }

    return (
        <section id="shipping">
            <div className="ms-Grid" dir="ltr">
                <div className={clsx(Styles.headerContainer, 'ms-Grid-row')}>
                    <div className={clsx('ms-Grid-col', 'ms-sm6')}>
                        <h2 className={clsx(Styles.header)}>
                            {t('billing_address')}
                        </h2>
                        {billingAddress && !isEditing && (Object.entries(selectedShippingOffers).length !== 0 && selectedShippingOffers.constructor === Object) && <EditButton />}
                    </div>
                    <div className={clsx('ms-Grid-col', 'ms-sm6')}>
                        {Object.entries(selectedShippingOffers).length !== 0 && selectedShippingOffers.constructor === Object ? (
                            <Checkbox
                                checked={useShippingData}
                                label={t('use_shipping_address_data')}
                                onChange={handleUseShippingData}
                                styles={checkboxStyles}
                                disabled={walkInCenterOfferSelected}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <AddressManager
                readonly={!isEditing && (Object.entries(selectedShippingOffers).length !== 0 && selectedShippingOffers.constructor === Object)}
                isWalkInCenter={walkInCenterOfferSelected}
                onContinue={onContinue}
                defaultAddress={useShippingData ? shippingAddress : walkInCenterOfferSelected ? undefined : Object.entries(billingAddress).length === 0 ? undefined : billingAddress}
                isEnterpriseCustomer={isEnterpriseCustomer}
            />
        </section>
    );
}
